import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Head from 'components/head';
import Title from 'components/title';
import Logo from 'components/logo';
// import Link from 'components/link';
// import { withPrefix } from 'gatsby-link';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import MarkdownWrapper from 'components/markdownwrapper';
import FormContact from 'components/formcontact';

export const Wrapper = styled.div`
  input,
  button {
    margin-bottom: 2rem;
    width: 100%;
  }
  ${MEDIA.MIN_LAPTOP`
    input,
    button {
      float: left;
      margin-right: 4%;
      width: 48%;
    }
    p {}
  `};
`;

export const ButtonStyle = styled.input`
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 12px 0;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.primary};
  border-width: 0;
  border-radius: 6px;
  letter-spacing: 1.5px;
  cursor: pointer;
`;

export const CenterItem = styled.div`
  text-align: center;
`;

const Contact = ({ data }) => (
  <Layout>
    <Head pageTitle={data.contactJson.title} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <CenterItem>
            <Logo />
            <Title as="h1" size="large">
              Contact us
            </Title>
          </CenterItem>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    <Container>
      <Row justify="center">
        <Col col={12} sm={12} md={8}>
          <MarkdownWrapper>
            <div
              dangerouslySetInnerHTML={{
                __html: data.contactJson.aside.childMarkdownRemark.html,
              }}
            />
          </MarkdownWrapper>
        </Col>
      </Row>
      <Row justify="center">
        <Col col={12} sm={12} md={8}>
          <MarkdownWrapper>
            <div
              dangerouslySetInnerHTML={{
                __html: data.contactJson.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownWrapper>
          <div style={{ marginTop: '1rem', marginBottom: '4rem' }}>
            <FormContact />
          </div>

          {/* <form
            id="contact"
            className="formspree"
            style={{ marginTop: '2rem', marginBottom: '4rem', display: 'none' }}
            name="contact"
            action="https://formspree.io/info@tradeincool.com"
            method="POST"
          >
            <input
              type="hidden"
              name="form-name"
              value="Fly Jet Center Contact"
            />
            <input
              type="hidden"
              name="bot-field"
              value="info@tradeincool.com"
            />
            <input type="text" name="_gotcha" style={{ display: 'none' }} />
            <input type="hidden" name="_subject" value="Contact Form" />
            <input name="_next" value={withPrefix('/thanks')} type="hidden" />

            <div className="form-group">
              <label id="label-name" htmlFor="name">
                Name <span>(required)</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter Name"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="_replyto">
                Email <span>(required)</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="_replyto"
                name="_replyto"
                placeholder="Enter email"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="usrtel">Telephone</label>
              <input
                type="tel"
                className="form-control"
                id="usrtel"
                name="usrtel"
                placeholder="Enter Telephone"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">
                Message <span>(required)</span>
              </label>
              <textarea
                rows="5"
                className="form-control"
                id="message"
                name="message"
                placeholder="Enter Message"
                autoComplete="off"
                autoCorrect="off"
                required
              />
            </div>

            <Wrapper className="form-group justify-content-end">
              <ButtonStyle type="submit" id="send" value="Send" />
              <p>
                or email us at{' '}
                <Link href="mailto:info@jet60.com" page="mailto:info@jet60.com">
                  info@jet60.com
                </Link>
              </p>
            </Wrapper>
          </form> */}
        </Col>
      </Row>
    </Container>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      aside {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
