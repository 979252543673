import React, { Component } from 'react';
import axios from 'axios';
import { Formik, ErrorMessage } from 'formik';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Link from 'components/link';

export const Wrapper = styled.div`
  display: block;

  ${MEDIA.MIN_LAPTOP`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem;
  `};

  button {
    margin-bottom: 2rem;
    width: 100%;
  }
  // ${MEDIA.MIN_LAPTOP`
  //   button {
  //     float: left;
  //     margin-right: 4%;
  //     width: 48%;
  //   }
  //   p {}
  // `};
`;

export const ButtonStyle = styled.button`
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 12px 0;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.primary};
  border-width: 0;
  border-radius: 6px;
  letter-spacing: 1.5px;
  cursor: pointer;
`;

class FormContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitSuccess: false,
      formMessage: null,
    };
  }

  handleFormSubmitSuccess() {
    this.setState({
      submitSuccess: true,
      formMessage:
        'Contact Form Successly Submitted. We are processing your request. If this is urgent, please use our telephone number listed on the contact page.',
    });
  }

  /*eslint-disable no-unused-vars*/
  handleFormSubmitError(error) {
    /*eslint-enable no-unused-vars*/
    this.setState({
      submitSuccess: false,
      formMessage:
        'Error submitting form. Please try again or email us directly.',
    });
  }

  render() {
    return (
      <Formik
        initialValues={{
          name: '',
          email: '',
          message: '',
        }}
        validate={({ name, email, message }) => {
          const errors = {};

          if (!name) errors.name = true;

          if (
            !email ||
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
          ) {
            errors.email = true;
          }

          if (!message) errors.message = true;

          return errors;
        }}
        onSubmit={(
          { name, email, message },
          { setSubmitting, setErrors, resetForm } // eslint-disable-line no-unused-vars
        ) => {
          const endPoint = `${process.env.FUNCTION_API}`;

          axios
            .post(endPoint + 'contact/ses', {
              // HACK: Endpoint expects name property
              name,
              email,
              message,
            })
            .then(response => {
              if (response.status === 200) {
                this.handleFormSubmitSuccess();
                resetForm();
              } else {
                this.handleFormSubmitError();
              }
            })
            /*eslint-disable no-unused-vars*/
            .catch(error => {
              /*eslint-enable no-unused-vars*/
              this.handleFormSubmitError();
            });
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label id="label-name" htmlFor="name">
                Name <span>(required)</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                border={touched.name && (errors.name ? 'error' : '')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                placeholder="Enter Name"
                autoComplete="on"
                autoCorrect="off"
                spellCheck="false"
                required
              />
              <ErrorMessage name="name" className="error" component="div" />
            </div>

            <div className="form-group">
              <label htmlFor="email">
                Email <span>(required)</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                border={touched.email && (errors.email ? 'error' : '')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Enter email"
                autoComplete="on"
                autoCorrect="off"
                spellCheck="false"
                required
              />
              <ErrorMessage name="email" className="error" component="div" />
            </div>

            <div className="form-group">
              <label htmlFor="message">
                Message <span>(required)</span>
              </label>
              <textarea
                rows="5"
                id="message"
                name="message"
                className="form-control"
                border={touched.message && (errors.message ? 'error' : '')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                placeholder="Enter Message"
                autoComplete="off"
                autoCorrect="off"
                required
              />
              <ErrorMessage name="message" className="error" component="div" />
            </div>

            {this.state.formMessage && (
              <div className="form-group">
                <span
                  style={{
                    color: this.state.submitSuccess ? '#0093BF' : '#d81f44',
                  }}
                >
                  {this.state.formMessage}
                </span>
              </div>
            )}

            <Wrapper className="form-group justify-content-end">
              <ButtonStyle type="submit" id="send" disabled={isSubmitting}>
                Send Message
              </ButtonStyle>
              <p>
                or email us at{' '}
                <Link
                  href="mailto:info@flyjetcenter.com"
                  page="mailto:info@flyjetcenter.com"
                >
                  info@flyjetcenter.com
                </Link>
              </p>
            </Wrapper>
          </form>
        )}
      />
    );
  }
}

FormContact.propTypes = {};
FormContact.defaultProps = {};

export default FormContact;
